import { Collapse, List, NavBar } from 'antd-mobile';
import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { LocationFill, MessageOutline, RedoOutline, UserCircleOutline } from 'antd-mobile-icons';
import { PhoneOutlined } from '@ant-design/icons';
import Wrapper from '../../layout/Wrapper';
import { useTelegram } from '../../../features/hooks';

const MorePage: FC = observer(() => {
  const tg = useTelegram()
  const go = useNavigate()
  return (
    <Wrapper>
      <NavBar onBack={() => { go(-1) }}>
        Еще
      </NavBar>
      <List style={{ borderRadius: 20, overflow: 'hidden' }}>
        <List.Item prefix={<LocationFill style={icoStyle} />}>
          Уфа
        </List.Item>
        <List.Item
          clickable={false}
          prefix={<PhoneOutlined style={icoStyle} />}
          onClick={() => {
            window.open('tel:89870401199')
          }}
        >
          8-987-040-11-99
        </List.Item>
        <List.Item
          clickable={false}
          prefix={<MessageOutline style={icoStyle} />}
          onClick={() => {
            tg.isInTelegram()
              ? tg.tg.openTelegramLink('https://t.me/+79870401199')
              : window.open('https://wa.me/+79870401119')
          }}
        >
          Поддержка
        </List.Item>
        <List.Item
          clickable={false}
          prefix={<UserCircleOutline style={icoStyle} />}
          onClick={() => go('/me')}
        >
          Профиль
        </List.Item>
        <List.Item
          clickable={false}
          prefix={<RedoOutline style={icoStyle} />}
          onClick={() => go('/orders')}
        >
          История заказов
        </List.Item>
        <Collapse>
          <Collapse.Panel key='1' title='Пользовательское соглашение'>
            <UserAgreement />
          </Collapse.Panel>
          <Collapse.Panel key='2' title='Реквизиты'>
            <Requisites />
          </Collapse.Panel>
        </Collapse>
      </List>
    </Wrapper>
  )
})

const icoStyle = {
  fontSize: '24px',
  color: 'var(--gurmag-accent-color)',
  marginRight: '10px'
}

const UserAgreement: FC = () => <span style={{ color: 'var(--громкий-текст)', fontSize: 14 }}>
  Пользовательское соглашение<br />
  Настоящее пользовательское соглашение является публичной офертой (то есть предложением заключить соглашение). Нажимая кнопки на сайте либо в приложениях Gurmag, предусматривающих отправку персональных данных, вы считаетесь присоединившимся к настоящему Соглашению. При изменении законодательства, в Соглашение могут вноситься изменения. Если вы считаете, что Соглашение или Администрация Сайта нарушает ваши права, сообщите об этом по электронному адресу: x9177547618@gmail.com <br />
  Термины, применяемые в настоящем Соглашении <br />
  Сайт — это совокупность текстов, графических элементов, дизайна, изображений, программного кода, фото- и видеоматериалов и иных результатов интеллектуальной деятельности, содержащихся в сети Интернет под доменным именем gurmag.ru <br />
  Администрация Сайта — это лицо, обладающее правами администрирования Сайта, ИП Юсупов Ильяс Раисович, ОГРНИП 314028000095063, ИНН 027410493250, адрес: 450105, г. Уфа, ул. М. Жукова 10, кафе Гурмаг-ЕлиПели <br />
  Пользователь — это любое лицо, осуществившее вход на Сайт или воспользовавшееся приложением Gurmag и принявшее условия настоящего Соглашения, независимо от факта прохождения процедур регистрации и авторизации. <br />
  Использование персональных данных <br />
  Принимая условия настоящего соглашения, Пользователь предоставляет Администрации Сайта согласие на обработку своих персональных данных. <br />
  Цель обработки персональных данных: <br />
  •	идентификация стороны при исполнении обязательств Рестораном по доставке товаров; <br />
  •	предоставление пользователю персонализированных сервисов; <br />
  •	связь с пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования сервисов, оказания услуг, а также обработка запросов и заявок от пользователя; <br />
  •	улучшение качества приложения, удобства его использования, разработка новых сервисов и услуг; <br />
  •	проведение статистических и иных исследований на основе обезличенных данных. <br />
  Обработке подлежат следующие персональные данные: <br />
  •	Имя Пользователя; <br />
  •	электронный адрес Пользователя; <br />
  •	номер телефона Пользователя. <br />
  •	адрес доставки продукции «Гурмаг» Пользователю <br />
  Под обработкой персональных данных подразумевается следующий перечень действий с персональными данными: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление доступа), обезличивание, блокирование, удаление, уничтожение персональных данных. <br />
  Персональные данные получаются в связи с заключением договора, стороной которого является субъект персональных данных. Персональные данные не распространяются, а также не предоставляются третьим лицам без согласия субъекта персональных данных и используются оператором исключительно для исполнения указанного соглашения и заключения договоров с субъектом персональных данных. <br />
  Администрация имеет право отправлять информационные, в том числе рекламные сообщения, на электронную почту и мобильный телефон Пользователя с его согласия, выраженного посредством совершения им действий, однозначно идентифицирующих этого абонента и позволяющих достоверно установить его волеизъявление на получение сообщения. Пользователь вправе отказаться от получения рекламной и другой информации без объяснения причин отказа, проинформировав Администрацию о своем отказе по электронной почте x9177547618@gmail.com или лично, по адресу: 450105, г. Уфа, ул. М. Жукова 10, кафе Гурмаг-ЕлиПели <br />
  Данное согласие на обработку персональных данных действует бессрочно, но может быть отозвано субъектом персональных данных на основании личного заявления, направленного по электронной почте x9177547618@gmail.com или лично, по адресу: 450105, г. Уфа, ул. М. Жукова 10, кафе Гурмаг-ЕлиПели <br />
  Администрация Сайта обязуется использовать персональные данные Пользователя, полученные в результате использования Сайта в соответствии с требованиями законодательства о защите персональных данных, в том числе федерального закона № 152-ФЗ от 22.02.2017 «О персональных данных» в редакции, действующей на момент обработки таких персональных данных. <br />


</ span>
const Requisites: FC = () => <span style={{ color: 'var(--громкий-текст)', fontSize: 14 }}>
  РЕКВИЗИТЫ<br />
  <br />
  Индивидуальный предприниматель Юсупов Ильяс Раисович<br />
  ИНН: 027410493250<br />
  ОГРНИП: 314028000095063<br />
  Юридический адрес:  450074, г. Уфа, ул. Г. Амантая 7/1, кв. 74<br />
  Фактический адрес:  450105, г. Уфа, ул. М. Жукова 10, кафе Гурмаг-ЕлиПели<br />
  Электронная почта: x9177547618@gmail.com<br />
  Расчетный счет: 40802810006000015374 в БАШКИРСКОМ ОТДЕЛЕНИИ N8598 ПАО СБЕРБАНК г. УФА<br />
  БИК: 048073601<br />
  К/с: 30101810300000000601<br />
  <br />
  Контакты <br />
  8-987-040-11-99 <br />
  8-987-040-11-19 <br />
</span>
export default MorePage