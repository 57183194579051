import { FC } from 'react'

export const IconClose: FC<{ color: any, width: any, height: any }> = props =>
<svg
  x="0px"
  y="0px"
  xmlns="http://www.w3.org/2000/svg"
  viewBox={`0 0 ${props.width} ${props.height}`}
  width={props.width}
  height={props.height}
>
	<g>
		<path
      fill={props.color}
      d="M5.2,5.2c0.5,0,0.8,0.4,1.1,0.7C7.4,6.9,8.5,8,9.5,9.1c0.5,0.6,1,0.6,1.6,0c1-1.1,2.1-2.1,3.2-3.2
        c0.5-0.5,1.1-1.1,1.8-0.4c0.8,0.7,0.1,1.4-0.4,1.9c-1,1-2,2-3,3c-0.7,0.6-0.8,1.1,0,1.8c1.1,1,2.1,2,3.1,3.1
        c0.5,0.5,1.1,1.2,0.4,1.8c-0.7,0.7-1.3,0.1-1.8-0.4c-1-1-2.1-2-3.1-3.1c-0.7-0.8-1.2-0.6-1.8,0c-1,1-2,2-3,3
        c-0.4,0.4-0.7,0.7-1.3,0.7c-0.4,0-0.7-0.2-0.9-0.6c-0.2-0.5,0-0.9,0.3-1.2c1.1-1.1,2.1-2.2,3.3-3.3c0.8-0.7,0.9-1.2,0-2
        C6.8,9.2,5.8,8.1,4.7,7C4.3,6.7,4.1,6.3,4.3,5.8C4.5,5.4,4.8,5.2,5.2,5.2z"
    />
	</g>
</svg>
